import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../images/logo.svg";
import { Link } from "gatsby";
import ReactGA from "react-ga";

const StyledWrapper = styled.div`
  .external-links {
    background-color: ${props => props.theme["borderBottomColor"]};
    text-align: center;
  }

  .header-external-links {
    margin-top: ${props => props.theme["pixel-20"]};
    font-size: 12px;
    color: ${props => props.theme["externalLinkColor"]};
    font-weight: normal;
    text-transform: uppercase;
  }

  .transform-sm {
    text-transform: unset;
  }

  .border-right {
    border-right: 1px solid ${props => props.theme["primaryColor"]} !important;
  }

  .upreach {
    color: ${props => props.theme["secondaryColor"]};
    font-size: ${props => props.theme["upreachNameHeader"]};
    font-weight: ${props => props.theme["footerLinkBold"]};
  }

  .grid-upper-container {
    display: grid !important;
    grid-template-columns: ${props => props.theme["percent-25"]} auto auto auto auto auto;
  }

  .grid-lower-container {
    display: grid !important;
    grid-template-columns: ${props => props.theme["percent-25"]} auto;
  }

  .internal-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    font-size: ${props => props.theme["headerLinkSize"]};
    font-weight: ${props => props.theme["headingBold"]};
  }

  .internal-links-a-tag.donate {
    color: ${props => props.theme["borderBottomColor"]};
    background-color: #464FD0;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 7px; 
    padding-bottom: 7px;
    color: white;
  }

  .internal-links-a-tag {
    color: ${props => props.theme["primaryColor"]};
    position: relative;
    top: 5px;
    width: fit-content;
    margin-left: auto;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.5s;
  }

  .internal-links-a-tag:hover {
    border-bottom: 2px solid #464fd0;
    text-decoration: none;
  }

  .active {
    border-bottom: 2px solid #464fd0;
  }

  .header-mobile-desktop-visibility {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .sticky-nav {
    background-color: white;
    width: 100%;
    z-index: 9;
  }

  .upreach-transforming {
    position: relative;
    top: 15px;
  }

  .upreach-program {
    position: relative;
    left: -5px;
  }

  .main__logo {
    max-width: 200px;
  }
  .dropdown-menu {
    // width:130px !important;
    // min-width:100px !important;
    padding: 0px;
  }
  .dropdown-item {
    text-transform: uppercase !important;
    font-size: ${props => props.theme["headerLinkSize"]} !important;
    font-weight: ${props => props.theme["headingBold"]} !important;
    padding: 10px 0px 5px 10px !important;
  }
  .dropdown-item > a {
    color: black !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    max-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 10px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
`;

const Header = () => {
  const [fixed, setFixed] = React.useState(80);
  const [position, setPosition] = React.useState(null);

  const pixelValue = fixed + "px";

  React.useEffect(() => {
    window.addEventListener("scroll", e => {
      if (window.pageYOffset > 100) {
        setFixed(0);
        setPosition("fixed");
      }
      if (window.pageYOffset < 100) {
        setFixed(80);
        setPosition("unset");
      }
    });
  }, []);

  const handleDonate = () => {
    ReactGA.event({
      category: "Nav bar",
      action: "Donate Button Click",
    });
  };

  return (
    <StyledWrapper>
      <div className="header-mobile-desktop-visibility">
        <div className="grid-upper-container pb-3 external-links">
          <div className="item1">
            <div className="upreach-transforming">
              <p className="header-external-links upreach upreach-program my-0">
                UPREACH PROGRAMMES
              </p>
            </div>
          </div>
          <div className="item2 white-background">
            <Link target="_blank" to="https://aspire.upreach.org.uk">
              <p className="header-external-links border-right px-3">
                Sixth-Form
              </p>
            </Link>
          </div>
          <div className="item3 white-background">
            <Link target="_blank" to="https://realrating.co.uk">
              <p className="header-external-links border-right px-3">
                Contextualised Recruitment
              </p>
            </Link>
          </div>
          <div className="item4 white-background">
            <Link target="_blank" to="https://getemployable.org">
              <p className="header-external-links border-right px-3">
                Graduate Employability Framework
              </p>
            </Link>
          </div>
          <div className="item5 white-background">
            <Link target="_blank" to="http://socialmobilitynetwork.org.uk/">
              <p className="header-external-links border-right px-3">
                Social Mobility Network
              </p>
            </Link>
          </div>
          <div className="item6 white-background">
            <Link
              target="_blank"
              to="https://studentsocialmobilityawards.org.uk"
            >
              <p className="header-external-links px-3">
                STUDENT SOCIAL MOBILITY AWARDS
              </p>
            </Link>
          </div>
        </div>
        <div
          className="sticky-nav"
          style={{ top: pixelValue, position: position }}
        >
          <div className="grid-lower-container py-4">
            <div className="item1">
              <Link to="/">
                <img className="mx-auto d-flex main__logo" src={Logo} alt="" />
              </Link>
            </div>
            <div className="internal-links pr-1 pr-md-3 pr-lg-5">
              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag"
                to="/students"
              >
                <div class="dropdown">
                  <span>STUDENTS</span>
                  <div class="dropdown-content">
                    <Link to="/apply-now">APPLY NOW</Link>
                    <Link to="/students/#programme">PROGRAMME</Link>
                    <Link to="/students/#eligibility">ELIGIBILITY</Link>
                    <Link to="/pledge">PLEDGE</Link>
                  </div>
                </div>
              </Link>

              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag"
                to="/universities"
              >
                Universities
              </Link>

              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag"
                to="/employers"
              >
                Employers
              </Link>

              <Link activeClassName="active" className="internal-links-a-tag">
                <div class="dropdown">
                  <span>Get Involved</span>
                  <div class="dropdown-content">
                    <Link to="/involved/#fundraising">FUNDRAISING</Link>
                    <Link to="/involved/#getInvolved">VOLUNTEER</Link>
                    <Link to="/involved/#donateSummary">DONATE</Link>
                  </div>
                </div>
              </Link>

              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag"
                to="/about"
              >
                About
              </Link>

              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag"
                to="/news/#pressrelease"
              >
                News
              </Link>
              {/* <Link
                activeClassName="active"
                partiallyActive={true}
                target="_blank"
                className="internal-links-a-tag donate"
                to="https://cafdonate.cafonline.org/4581#!/DonationDetails#%2FDonationDetails"
              >
                <span onClick={handleDonate}>Donate</span>
              </Link> */}
              <Link
                activeClassName="active"
                partiallyActive={true}
                className="internal-links-a-tag donate"
                to="/donate"
              >
                Donate
              </Link>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Header;
